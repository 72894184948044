/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3cb66f");
@import "~react-image-gallery/styles/css/image-gallery.css";

html {
	scroll-behavior: smooth;
}

a {
	color: black;
	font-weight: 500;
}

ul,
ol {
	list-style-position: inside;
	padding-inline-start: 0;
}

.whiteLink>a {
	color: white;
}

/* .MuiCheckbox-colorSecondary.Mui-checked {
	color: "white" !important;
} */

.MuiInput-underline::after {
	border-bottom: 2px solid gray !important;
}

.MuiAccordion-rounded:last-child {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.MuiPaper-rounded {
	border-radius: 0 !important;
}

.MuiButton-root {
	border-radius: 0 !important;
}

@font-face {
	font-display: swap;
	font-family: "Magnite";
	src: url("/fonts/magnite/magnite-alt-italic.ttf");
}

@font-face {
	font-display: swap;
	font-family: 'OptimaLTStd-Bold';
	src: url('/fonts/optima/OptimaLTStd-Bold/font.woff2') format('woff2'),
		url('/fonts/optima/OptimaLTStd-Bold/font.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'OptimaLTStd-MediumItalic';
	src: url('/fonts/optima/OptimaLTStd-MediumItalic/font.woff2') format('woff2'),
		url('/fonts/optima/OptimaLTStd-MediumItalic/font.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'OptimaLTStd-ExtraBlack';
	src: url('/fonts/optima/OptimaLTStd-ExtraBlack/font.woff2') format('woff2'),
		url('/fonts/optima/OptimaLTStd-ExtraBlack/font.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'OptimaLTStd';
	src: url('/fonts/optima/OptimaLTStd/font.woff2') format('woff2'),
		url('/fonts/optima/OptimaLTStd/font.woff') format('woff');
}

img.image-gallery-image {
	height: 320px;
}

@media screen and (min-width: 768px) {
	img.image-gallery-image {
		height: 400px;
	}
}

@media screen and (min-width: 1024px) {
	img.image-gallery-image {
		height: 450px;
	}
}

p {
	white-space: pre-wrap;
}

.image-gallery-slide {
	outline: none !important;
}

.image-gallery-thumbnail.active {
	border: 2px solid;
	border-color: gray !important;
}

.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
	border: 1px solid;
	border-color: gray !important;
}

button.image-gallery-thumbnail {
	border: none !important;
}

button.image-gallery-thumbnail.active {
	border: 2px solid !important;
	border-color: gray !important;
}

div.policy-page>* {
	word-wrap: break-word;
	max-width: 95vw;
}

.instagram-media.instagram-media-rendered {
	margin: auto !important;
}

span.code-productlisting-pre-text>div>div {
	padding: 0;
}

#component-wrapper>.MuiContainer-root {
	padding: 0 !important;
}

blockquote.instagram-media {
	margin: auto !important;
}